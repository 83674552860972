<template>
  <div class="approval-list">
    <el-table
      v-loading="loading"
      :data="tableData"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      @select="selectSomeCheck"
      @select-all="selectAllCheck"
    >
      <div slot="empty" style="height: 200px; line-height: 200px">
        {{ $t("label.dashboard.noRecord") }}
      </div>
      <el-table-column type="index"></el-table-column>
      <el-table-column
        width="50"
        type="selection"
        v-if="selectMenu == '0'"
      ></el-table-column>
      <el-table-column
        prop
        :label="$t('label.operate')"
        width="150"
        v-if="selectMenu == '0'"
      >
        <template slot-scope="scope">
          <div class="action">
            <span
              @click="deliver(scope.row)"
              v-show="scope.row.isInsertApproval !== 'true'"
            >
              <!-- 重新分配  -->
              {{ $t("label.redistribution") }}
              |</span
            >
            <span @click="approval(scope.row)">
              <!-- 进行审批 -->
              {{ $t("label.approveorrefuse") }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- 相关项 -->
      <el-table-column
        :min-width="item.field == 'actdate' ? '100' : ''"
        :prop="item.field"
        :label="item.title"
        :key="index + Math.random()"
        v-for="(item, index) in objNameArr"
        class-name="relativeItem"
      >
        <template slot-scope="scope">
          <a
            :href="`#/commonObjects/detail/${scope.row.objid}/DETAIL`"
            target="_blank"
            class="objnameStyle"
            @click.prevent="objnameTurn(scope.row.objid)"
            >{{ scope.row.objname }}</a
          >
        </template>
      </el-table-column>
      <el-table-column
        :min-width="item.field == 'actdate' ? '100' : ''"
        :prop="item.field"
        :label="item.title"
        :key="index"
        v-for="(item, index) in tableTitle"
      >
      </el-table-column>
      <el-table-column
        prop
        :label="$t('label.zhaiyao')"
        v-if="selectMenu == '0'"
        min-width="300"
        max-height="45"
      >
        <template slot-scope="scope">
          <div v-html="tableData[scope.$index].abstract"></div>
        </template>
      </el-table-column>
    </el-table>
    <div class="box" style="height: 40px"></div>
  </div>
</template>

<script>
/*
 * 审批列表展示
 */
export default {
  name: "approcal-list",
  props: {
    //当前选中审批类型
    selectMenu: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //列表loading
      loading: true,
      //表格数据列表
      tableData: [],
      //表格数据表头
      tableTitle: [],
      //待审批头部
      waitTitle: [
        // { title: "是否代理", field: "isdl" },
        { title: this.$i18n.t("label.relationobject.isdl"), field: "isdl" },
        // { title: "委派人", field: "wpr" },
        { title: this.$i18n.t("label.relationobject.wpr"), field: "wpr" },
        // { title: "相关项", field: "objname" },
        // {
        //   title: this.$i18n.t("component_telerecord_label_related"),
        //   field: "objname"
        // },
        // { title: "类型", field: "objtype" },
        { title: this.$i18n.t("label.type"), field: "objtype" },
        // { title: "提交人", field: "actor" },
        {
          title: this.$i18n.t("label.forecast.forehistory.submitor"),
          field: "actor",
        },
        // { title: "提交时间", field: "actdate" },
        { title: this.$i18n.t("label.refertime"), field: "actdate" },
      ],
      //提交的审批头部
      commitTitle: [
        //  { title: "相关项", field: "objname" },
        // {
        //   title: this.$i18n.t("component_telerecord_label_related"),
        //   field: "objname"
        // },
        // { title: "类型", field: "objtype" },
        { title: this.$i18n.t("label.type"), field: "objtype" },
        // { title: "当前审批人", field: "currentactor" },
        {
          title: this.$i18n.t("label.approval.his.currentactor"),
          field: "currentactor",
        },
        // { title: "审批状态", field: "status" },
        { title: this.$i18n.t("label.audit.status"), field: "status" },
        // { title: "提交人", field: "actor" },
        {
          title: this.$i18n.t("label.forecast.forehistory.submitor"),
          field: "actor",
        },
        // { title: "提交时间", field: "actdate" },
        { title: this.$i18n.t("label.refertime"), field: "actdate" },
      ],
      //批准与拒绝的头部
      elseTitle: [
        // { title: "是否代理", field: "isdl" },
        { title: this.$i18n.t("label.relationobject.isdl"), field: "isdl" },
        // { title: "委派人", field: "wpr" },
        { title: this.$i18n.t("label.relationobject.wpr"), field: "wpr" },
        // { title: "相关项", field: "objname" },
        // {
        //   title: this.$i18n.t("component_telerecord_label_related"),
        //   field: "objname"
        // },
        // { title: "类型", field: "objtype" },
        { title: this.$i18n.t("label.type"), field: "objtype" },
        // { title: "审批状态", field: "status" },
        { title: this.$i18n.t("label.audit.status"), field: "status" },
        // { title: "提交人", field: "actor" },
        {
          title: this.$i18n.t("label.forecast.forehistory.submitor"),
          field: "actor",
        },
        // { title: "提交时间", field: "actdate" },
        { title: this.$i18n.t("label.refertime"), field: "actdate" },
      ],
      //相关项表头数组
      objNameArr: [
        // { title: "相关项", field: "objname" },
        {
          title: this.$i18n.t("component_telerecord_label_related"),
          field: "objname",
        },
      ],
    };
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    if (this.$route.path == "/approvalCenter/approvalList") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
    this.$Bus.$on("deliver-table-title-and-body",this.deliverTableTitleAndBodyCallback);
    this.$Bus.$on("show-loading", this.showLoadingCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-table-title-and-body",this.deliverTableTitleAndBodyCallback);
    this.$Bus.$off("show-loading", this.showLoadingCallback);
  },
  watch: {
    selectMenu: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.tableTitle = [];
          if (newVal == "0") {
            this.tableTitle = this.waitTitle;
          } else if (newVal == "1") {
            this.tableTitle = this.commitTitle;
          } else {
            this.tableTitle = this.elseTitle;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    deliverTableTitleAndBodyCallback (body) {
      this.tableData = body;
    },
    showLoadingCallback(loading) {
      this.loading = loading;
    },
    /*
     * 点击相关项跳转详情
     */
    objnameTurn(objId) {
      this.$router.push({
        path: `/commonObjects/detail/${objId}/DETAIL`,
      });
    },
    /*
     * 选中所有数据行
     */
    selectAllCheck(val) {
      this.$Bus.$emit("batch-approval-id", val);
    },
    /*
     * 选中某些数据行
     */
    selectSomeCheck(val) {
      //  let arr = val.map(res=>{
      //    return res.workItemid
      //  });
      //  arr = arr.join(';');
      this.$Bus.$emit("batch-approval-id", val);
    },
    /*
     * 重新分配
     */
    deliver(value) {
      this.$router.push({
        path: "/approvalCenter/distributionAagain",
        query: { value: value.workItemid },
      });
    },
    /*
     * 进行审批
     */
    approval(value) {
      let option = {
        objid: value.objid,
        workItemid: value.workItemid,
        objname: value.objname,
      };
      this.$router.push({
        path: "/approvalCenter/approvalRequest",
        query: { value: JSON.stringify(option) },
      });
      this.$Bus.$emit("approval-show-or-hidden", true);
    },
    /*
     * 更改表格颜色
     */
    tableRowClassName({  rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "single";
      } else {
        return "double";
      }
    },
    /*
     * 跳转详情
     */
    goColDetail(row, col, ) {
      if (col.property == "objname") {
        this.$router.push({
          path: `/commonObjects/detail/${row.objid}/DETAIL`,
        });
      }
    },
  },
};
</script>

<style lang='scss'>
.relativeItem {
  cursor: pointer;
  color: #0b5b9c;
}
.relativeItem.is-leaf {
  color: #909399;
}
.approval-list {
  word-break: break-all;
  padding: 10px;
  .single {
    background: #f3f2f2;
  }
  .double {
    background: #fff;
  }
  .el-table {
    font-size: 12px;
  }
  .action {
    color: #006dcc;
    cursor: pointer;
  }
  .el-table .cell {
    max-height: 50px;
  }
  .approval-list {
    word-break: break-all;
    padding: 10px;
    .single {
      background: #f3f2f2;
    }
    .double {
      background: #fff;
    }
    .el-table {
      font-size: 12px;
    }
    .action {
      color: #006dcc;
      cursor: pointer;
    }
    .el-table .cell {
      max-height: 50px;
    }
    ::v-deep .el-table td {
      vertical-align: middle;
    }
    a{
      color: #006dcc;
    }
    .el-table td, .el-table th {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: left;
}
  }
  a {
    color: #006dcc;
  }
  .el-table td,
  .el-table th {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: left;
  }
}
</style>
