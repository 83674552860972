<template>
  <div class="page-bottom__">
    <div class="left-page">
      <!-- 每页显示记录数： -->
      {{$t('label.page.showamount')}}:
                                            <!-- 请选择 -->
      <el-select v-model="pageNumber" :placeholder="$t('label.select.please')" @change="changePageNumber">
        <el-option
          v-for="item in pageArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <!--共--> {{$t('label.multilang.totalpage2')}} {{totalPage}} <!--页-->{{$t('label.brief.page')}}，<!--当前页数-->{{$t('label.manageAll.selpage')}} {{currentPage}}
    </div>
    <div class="middle-page">
      <span class="up-page" :class="{'click' : currentPage != 1 }" @click="getUpPage">{{$t('label.page.previous')}}</span><!--上一页-->
      <span class="up-page" :class="{'click' : currentPage != totalPage }" @click="getDownPage">{{$t('label.page.next')}}</span><!--下一页-->
    </div>
    <div class="right-page">
      <!-- 跳转到 -->
      {{$t('label.product.gopage')}}
      <input type="text" @keydown.enter="linkSelectPage" v-model="linkPage" /> <!--页-->{{$t('label.brief.page')}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "page",
    data() {
      return {
        //当前页数
        currentPage: 1,
        //总页数
        totalPage: 1,
        linkPage: "",
        pageNumber: "10",
        pageArr: [
          {
            value: "10",
            label: "10",
          },
          {
            value: "50",
            label: "50",
          },
          {
            value: "100",
            label: "100",
          },
          {
            value: "200",
            label: "200",
          },
          {
            value: "500",
            label: "500",
          },
        ],
      };
    },
    mounted() {
      document.getElementById("lightning-loading").style.display = "none";
      this.$Bus.$on('change-link-page',this.ebFn1);

      this.$Bus.$on("deliver-all-page", this.ebFn2);
      this.$Bus.$on("change-current-page", this.ebFn3);
    },
    beforeDestroy() {
      this.$Bus.$off('change-link-page',this.ebFn1);
      this.$Bus.$off("deliver-all-page", this.ebFn2);
      this.$Bus.$off("change-current-page", this.ebFn3);
    },
    methods: {
      ebFn1(){
        this.linkPage = '';
      },
      ebFn2(alllpage)  {
        this.totalPage = alllpage;
      },
      ebFn3(){
        this.currentPage = 1;
      },
      //跳转到自定义页
      linkSelectPage() {
        if (this.linkPage != this.currentPage && this.linkPage >= 1) {
          this.currentPage = this.linkPage;
          this.$Bus.$emit("get-another-page", this.currentPage);
        }
      },
      //更改每页的数量
      changePageNumber(value) {
        this.$Bus.$emit("get-another-pageNumber", value);
        this.currentPage = 1;
      },
      //获取上一页
      getUpPage() {
        if (this.currentPage != 1) {
          this.currentPage--;
          this.$Bus.$emit("get-another-page", this.currentPage);
        }
      },
      //获取下一页
      getDownPage() {
        if (this.currentPage != this.totalPage) {
          this.currentPage++;
          this.$Bus.$emit("get-another-page", this.currentPage);
        }
      },
    },
  };
</script>

<style lang='scss'>
  .page-bottom__ {
    width: calc(100% - 20px);
    height: 40px;
    background: #f3f2f2;
    border: 1px solid #dddbda;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: #666666;
    font-size: 12px;
    z-index: 222;
    box-sizing: border-box;
    .left-page {
      .el-select {
        .el-input {
          width: 56px;
        }
        .el-input--suffix .el-input__inner {
          padding-right: 0;
          padding-left: 5px;
          height: 26px;
          box-sizing: border-box;
          line-height: 26px;
        }
        .el-input__icon {
          line-height: 20px;
        }
      }
    }
    .right-page {
      input {
        outline: none;
        background: #fff;
        border: 1px solid #dddbda;
        width: 40px;
      }
    }
    .up-page {
      margin-right: 20px;
    }
    .up-page.click {
      cursor: pointer;
    }
    .click:hover {
      color: #006dcc;
    }
  }
</style>