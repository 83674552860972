<template>
  <div class="approval-handle">
    <div class="select-button">
      <div class="button-item">
        <div class="title">
          <!-- 开始时间 -->
          {{ $t("label.starttime") }}
        </div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="startTime"
          :format="dateFormat"
          type="date"
          :placeholder="$t('label_tabpage_selectdate')"
        ></el-date-picker>
      </div>
      <div class="button-item">
        <div class="title">
          <!-- 结束时间 -->
          {{ $t("label.endtime") }}
        </div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="endTime"
          :format="dateFormat"
          type="date"
          :placeholder="$t('label_tabpage_selectdate')"
        ></el-date-picker>
      </div>
      <div class="button-item">
        <div class="title">
          <!-- 提交人 -->
          {{ $t("label.forecast.forehistory.submitor") }}
        </div>
        <input type="text" class="content" v-model="commitPeo" />
      </div>
      <div class="button-item">
        <div class="title">
          <!-- 类型 -->
          {{ $t("label.type") }}
        </div>
        <el-select v-model="type">
          <el-option
            v-for="(item, index) in typeArr"
            :key="index"
            :label="item.tabName"
            :value="item.tabId"
          ></el-option>
        </el-select>
      </div>
      <div class="button-item" v-if="selectMenu != '0'">
        <div class="title">
          <!-- 相关项 -->
          {{ $t("label.relateobj") }}
        </div>
        <input type="text" class="content" v-model="relevant" />
      </div>
      <div class="button-item">
        <div class="title"></div>
        <div class="status">
          <el-select v-model="allStatus" v-if="selectMenu != '0'">
            <el-option
              v-for="(item, index) in statusArr"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="button-item">
        <div class="title"></div>
        <div class="search" @click="searchApprovalList">
          <!-- 搜索 -->
          {{ $t("label.searchs") }}
        </div>
      </div>
      <div class="button-item" v-if="selectMenu == '0'">
        <div class="title"></div>
        <div class="approval-button" @click="batchApproval">
          <!-- 进行审批 -->
          {{ $t("label.approveorrefuse") }}
        </div>
        <div class="approval-button" @click="batchDistribution">
          <!-- 重新分配 -->
          {{ $t("label.redistribution") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 审批中心头部表单数据
 */
import { getApprovalList, getApprovalType } from "./api.js";
import * as Time from "@/utils/time";

export default {
  name: "approval-handle",
  props: {
    //当前选中审批类型
    selectMenu: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //时间转换
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      // 摘要选中小图标
      isSelectPicture: require("@/assets/approval/checkbox_checked.gif"),
      // 摘要未选中小图标
      unSelectPicture: require("@/assets/approval/checkbox_uhecked.gif"),
      //页数
      pageNum: 1,
      //每页数量
      pageSize: 10,
      //开始时间
      startTime: "",
      //结束时间
      endTime: "",
      //提交人
      commitPeo: "",
      //类型               --无--
      type: this.$i18n.t("select.nullvalue"),
      //类型数组            --无--
      typeArr: [{ tabId: "", tabName: this.$i18n.t("select.nullvalue") }],
      //相关项
      relevant: "",
      //查看全部下拉框  查看全部
      allStatus: this.$i18n.t("label.viewall"),
      //审批状态数组
      // ["查看全部", "审批中", "审批通过", "审批拒绝", "已调回"],
      statusArr: [
        this.$i18n.t("label.viewall"),
        this.$i18n.t("label.manageAll.sta1"),
        this.$i18n.t("label.manageAll.sta2"),
        this.$i18n.t("label.manageAll.sta3"),
        this.$i18n.t("lebel.alreadyback"),
      ],
      //表列表数据
      tableBodyList: [],
      //当前页数
      currentPage: 1,
      //每页的数量
      pageNumber: 10,
      //批量审批ID字符串
      batchApprovalue: [],
    };
  },
  activated() {
    this.$Bus.$on("cancel-not-loading", this.cancelNotLoadingCallback);
  },
  watch: {
    selectMenu: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$Bus.$emit("change-current-page", 1);
          this.tableTitle = [];
          this.tableBodyList = [];
          this.startTime = "";
          this.endTime = "";
          this.commitPeo = "";
          this.currentPage = 1;
          // --无--
          this.type = this.$i18n.t("select.nullvalue");
          this.relevant = "";
          // 查看全部
          this.allStatus = this.$i18n.t("label.viewall");
          this.$Bus.$emit("change-link-page", "");
          this.getApproList();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getApproType();
    this.$Bus.$on("batch-approval-id", this.batchApprovalIdCallback);

    this.$Bus.$on("get-another-page",this.getAnotherPageCallback);
    this.$Bus.$on("get-another-pageNumber", this.getAnotherPageNumberCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("cancel-not-loading", this.cancelNotLoadingCallback);
    this.$Bus.$off("batch-approval-id", this.batchApprovalIdCallback);
    this.$Bus.$off("get-another-page",this.getAnotherPageCallback);
    this.$Bus.$off("get-another-pageNumber", this.getAnotherPageNumberCallback);
  },
  methods: {
    batchApprovalIdCallback(arr){
      this.batchApprovalue = arr;
    },
    getAnotherPageCallback (currentPage){
      this.currentPage = currentPage;
      this.getApproList();
    },
    getAnotherPageNumberCallback(value){
      this.pageNumber = value;
      this.getApproList();
    },
    cancelNotLoadingCallback() {
      this.$Bus.$emit("change-current-page", 1);
      this.tableTitle = [];
      this.tableBodyList = [];
      this.startTime = "";
      this.endTime = "";
      this.commitPeo = "";
      this.currentPage = 1;
      // --无--
      this.type = this.$i18n.t("select.nullvalue");
      this.relevant = "";
      // 查看全部
      this.allStatus = this.$i18n.t("label.viewall");
      this.$Bus.$emit("change-link-page", "");
      this.getApproList();
    },
    /*
     * 批量审批
     */
    batchApproval() {
      if (this.batchApprovalue.length == 0) {
        this.$message({
          showClose: true,
          type: "warning",
          // 请至少选择一个值
          message: this.$i18n.t("message.chooseatleastone"),
        });
        return false;
      }
      this.$router.push({
        path: "/approvalCenter/batchApproval",
        query: { value: JSON.stringify(this.batchApprovalue) },
      });
    },
    /*
     * 批量分配
     */
    batchDistribution() {
      if (this.batchApprovalue.length == 0) {
        this.$message({
          showClose: true,
          type: "warning",
          // 请至少选择一个值
          message: this.$i18n.t("message.chooseatleastone"),
        });
        return false;
      }
      this.$router.push({
        path: "/approvalCenter/batchDistributionAagain",
        query: { value: JSON.stringify(this.batchApprovalue) },
      });
    },
    /*
     * 获取类型
     */
    async getApproType() {
      let result = await getApprovalType();
      if (result.result == true && result.data) {
        result.data.forEach((res) => {
          let obj = {};
          obj.tabName = res.label_name;
          obj.tabId = res.objId;
          this.typeArr.push(obj);
        });
      }
    },
    /*
     * 搜索审批列表
     */
    searchApprovalList() {
      this.getApproList();
    },
    /*
     * 获取审批列表
     */
    async getApproList() {
      //    --无--
      if (
        this.relevant != "" &&
        this.type == this.$i18n.t("select.nullvalue")
      ) {
        this.$message({
          showClose: true,
          type: "warning",
          //            '选择相关项必须选择一个类型'
          message: this.$i18n.t("label.approval.select.relatedobj"),
        });
        return false;
      }
      this.$Bus.$emit("show-loading", true);
      let type = "";
      //                             --无--
      if (this.type != this.$i18n.t("select.nullvalue")) {
        type = this.type;
      } else {
        type = "";
      }
      let approvalProcessStatus = "";
      switch (this.allStatus) {
        // case "查看全部":
        case this.$i18n.t(this.$i18n.t("label.viewall")):
          approvalProcessStatus = "";
          break;
        // case "审批中":
        case this.$i18n.t("label.manageAll.sta1"):
          approvalProcessStatus = "0";
          break;
        // case "审批通过":
        case this.$i18n.t("label.manageAll.sta2"):
          approvalProcessStatus = "1";
          break;
        // case "审批拒绝":
        case this.$i18n.t("label.manageAll.sta3"):
          approvalProcessStatus = "2";
          break;
        // case "已调回":
        case this.$i18n.t("lebel.alreadyback"):
          approvalProcessStatus = "3";
          break;
      }
      var option = {
        approvalProjectType: this.selectMenu,
        approvalProcessStatus: approvalProcessStatus,
        startTime: this.startTime,
        endTime: this.endTime,
        actor: this.commitPeo,
        related: this.relevant,
        type: type,
        pageNum: this.currentPage,
        pageSize: this.pageNumber,
      };
      let result = await getApprovalList(option);
      if (result.result == true && result.data.list == null) {
        this.$Bus.$emit("show-loading", false);
        this.tableBodyList = [];
      }
      if (result.result == true && result.data.list != null) {
        this.$Bus.$emit("show-loading", false);
        //获取数据总数
        this.$Bus.$emit("deliver-all-page", result.data.allpage);
        //待审批
        if (this.selectMenu == "0") {
          result.data.list.forEach((res) => {
            result.data.zylist.forEach((val) => {
              if (res.workItemid == val.id) {
                let index = val.zhaiyao.indexOf(
                  "../images/cloudcc/classic/checkbox_checked.gif"
                );
                while (index != -1) {
                  val.zhaiyao = val.zhaiyao.replace(
                    val.zhaiyao.substring(index, index + 46),
                    this.isSelectPicture
                  );
                  res.abstract = val.zhaiyao.replace(
                    val.zhaiyao.substring(index, index + 46),
                    this.isSelectPicture
                  );
                  index = val.zhaiyao.indexOf(
                    "../images/cloudcc/classic/checkbox_checked.gif",
                    index + 1
                  );
                }
                let index1 = val.zhaiyao.indexOf(
                  "../images/cloudcc/classic/checkbox_unchecked.gif"
                );
                while (index1 != -1) {
                  val.zhaiyao = val.zhaiyao.replace(
                    val.zhaiyao.substring(index1, index1 + 48),
                    this.unSelectPicture
                  );
                  res.abstract = val.zhaiyao.replace(
                    val.zhaiyao.substring(index1, index1 + 48),
                    this.unSelectPicture
                  );
                  index1 = val.zhaiyao.indexOf(
                    "../images/cloudcc/classic/checkbox_unchecked.gif",
                    index1 + 1
                  );
                }
                res.abstract = val.zhaiyao;
              }
              if (res.isdl == "true") {
                //                        是
                res.isdl = this.$i18n.t("label.checkbox.true");
              } else {
                //          否
                res.isdl = this.$i18n.t("label.checkbox.false");
                //          无
                res.wpr = this.$i18n.t("label.data.newtemplate.null");
              }
            });
          });
          // result.data.list.map((res, index) => {
          //   res.abstract =
          //     result.data.zylist.length > 0
          //       ? result.data.zylist[index].zhaiyao
          //       : "";
          //   if (res.isdl == "true") {
          //     res.isdl = "是";
          //   } else {
          //     res.isdl = "否";
          //     res.wpr = "无";
          //   }
          //   return res;
          // });
          this.tableBodyList = result.data.list;
        }

        if (this.selectMenu == "1" && result.data.list != null) {
          this.tableBodyList = result.data.list;
        }
        if (this.selectMenu == "2" || this.selectMenu == "3") {
          result.data.list.map((res, ) => {
            if (res.isdl == "true") {
              //                        是
              res.isdl = this.$i18n.t("label.checkbox.true");
            } else {
              //          否
              res.isdl = this.$i18n.t("label.checkbox.false");
              //          无
              res.wpr = this.$i18n.t("label.data.newtemplate.null");
            }
            return res;
          });
          this.tableBodyList = result.data.list;
        }
      }
      this.$Bus.$emit("deliver-table-title-and-body", this.tableBodyList);
    },
  },
};
</script>

<style lang='scss'>
.approval-handle {
  width: 100%;
  .select-button {
    width: 100%;
    padding-left: 25px;
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;
    .button-item {
      float: left;
      margin-right: 10px;
      .title {
        height: 21px;
        font-size: 12px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 5px;
      }
      .content {
        width: 150px;
        height: 30px;
        border: 1px solid #dddbda;
        border-radius: 3px;
        outline: none;
        font-size: 12px;
      }
      .search {
        width: 52px;
        height: 30px;
        background: #006dcc;
        border-radius: 3px;
        color: white;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
      .approval-button {
        cursor: pointer;
        padding: 0 10px;
        height: 30px;
        border-radius: 3px;
        border: 1px solid #dddbda;
        color: #006dcc;
        line-height: 30px;
        text-align: center;
        float: left;
        margin-right: 10px;
      }
      .status {
        .el-select .el-input__inner {
          width: 98px;
          font-weight: 400;
          color: #006dcc;
        }
      }
      .el-input__inner {
        font-size: 12px;
      }
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 150px;
        color: #006dcc;
      }
      .el-input--prefix .el-input__inner {
        width: 150px;
        height: 30px;
        border: 1px solid #dddbda;
        border-radius: 3px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select .el-input__inner {
        height: 30px;
        width: 82px;
        color: #507494;
        border-color: #dddbda;
        border-radius: 3px;
      }
      .el-select .el-input.is-focus .el-input__inner {
        border-color: #dddbda;
      }
    }
  }
  ::v-deep .el-select-dropdown__list {
    max-width: 300px;
  }
}
</style>