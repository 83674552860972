<template>
  <div class="approval-box">
    <div class="approval-center">
      <div class="approval-center-box">
        <div class="approval-title">
          <!-- 审批中心 -->
          {{ $t("label.manageAll.title") }}
        </div>
        <el-tabs type="border-card" v-model="selectMenu">
          <el-tab-pane
            :label="$t('label.manageAll.sel.one1')"
            name="0"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.manageAll.sel.one2')"
            name="1"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.manageAll.sel.one3')"
            name="2"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.manageAll.sel.one4')"
            name="3"
          ></el-tab-pane>
        </el-tabs>
        <approval-handle :selectMenu="selectMenu"></approval-handle>
        <approval-list :selectMenu="selectMenu"></approval-list>
      </div>
      <page></page>
    </div>
  </div>
</template>

<script>
/**
 * 审批中心
 */
import approvalHandle from "@/views/approval/components/approval-handle.vue";
import approvalList from "@/views/approval/components/approval-list.vue";
import page from "@/views/approval/components/page.vue";

export default {
  name: "approval",
  data() {
    return {
      //当前选中审批类型
      selectMenu: "0",
    };
  },
  activated() {
    if (this.$route.path == "/approvalCenter/approvalList") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
  components: {
    approvalHandle,
    approvalList,
    page,
  },
  methods: {
  },
};
</script>

<style lang='scss'>
.approval-box {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .approval-center {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    border-radius: 5px;
    .el-tabs--border-card {
      border-bottom: none;
    }
    .approval-center-box {
      width: 100%;
    }
    .approval-title {
      height: 49px;
      font-size: 18px;
      font-weight: 500;
      color: #222222;
      line-height: 49px;
      margin-left: 20px;
    }
    .el-tabs--border-card {
      box-shadow: none;
    }
    .el-tabs--border-card > .el-tabs__header {
      background-color: #f3f2f2;
      border-bottom: none;
      .el-tabs__item {
        color: #222222;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
      }
      .el-tabs__item.is-active {
        color: #006dcc;
      }
    }
  }
}

// ::-webkit-scrollbar {
//   display: none;
// }
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
